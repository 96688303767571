import styled from 'styled-components'
import { colors } from '../../styledPortal.config'

export const BreadcrumbStyled = styled.div`
  .breadcrumb__portal {
    padding-left: 0 !important;
    margin-bottom: 1.5rem;
    font-size: 0.75rem;
  }

  .ant-breadcrumb a:hover,
  .ant-breadcrumb li:last-child,
  .ant-breadcrumb li:last-child a {
    color: ${colors.grays66};
  }

  .ant-breadcrumb a {
    color: ${colors.grays66};
  }
`
