import { gql } from '@apollo/client'

export const FORGOTPASSWORD = gql`
  mutation Mutation($input: ForgotpasswordInput!, $action: ActionInput!) {
    forgotPassword(input: $input, action: $action) {
      email
    }
  }
`
export const CHANGEPASSWORD = gql`
  mutation Mutation($input: ChangePasswordInput!, $action: ActionInput!) {
    changePassword(input: $input, action: $action)
  }
`
