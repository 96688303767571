import { gql } from '@apollo/client'

export const VALIDATEURL = gql`
  query ValidateUrl($input: TokenDecrypt!, $action: ActionInput!) {
    validateUrl(input: $input, action: $action) {
      userId
      expireDate
    }
  }
`
