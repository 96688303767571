import { ErrorBoundary } from 'react-error-boundary'
import { Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert-error-boundary">
      <h2 className="title">Something went wrong!</h2>
      <pre>⚠️ {error.message}</pre>
      <pre>{error.stack}</pre>
      <Button
        danger
        onClick={resetErrorBoundary}
        icon={<ArrowLeftOutlined />}
        style={{ borderColor: '#b21e1e', color: '#b21e1e' }}
      >
        Back
      </Button>
    </div>
  )
}

export { ErrorBoundary, ErrorFallback }
