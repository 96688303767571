import { gql } from '@apollo/client'
export const LOGIN = gql`
  mutation login($input: LoginInput!, $action: ActionInput!) {
    login(input: $input, action: $action) {
      userId
      loginId
      email
      username
      firstname
      lastname
      firstnameEN
      lastnameEN
      accessToken
      refreshToken
      loginDate
      expireDate
      employeeId
      employeeCode
      employeeName
      organizationId
      organizationName
      positionId
      positionName
      profilePictureId
      signatureFileId
      roles {
        add
        menuCode
        view
        edit
        delete
        send
        export
        duplicate
        approve
        process
      }
    }
  }
`
