import React from 'react'
import { BreadcrumbStyled } from './BreadcrumbStyled'
import { Breadcrumb as BreadcrumbAntd } from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const BreadcrumbPortal = (props) => {
  const { breadbrumb } = props

  const breadcrumbItem = breadbrumb.map((item, key) => {
    return item.path ? (
      <BreadcrumbAntd.Item key={key}>
        <Link to={item.path}>{item.label ? item.label : ''}</Link>
      </BreadcrumbAntd.Item>
    ) : (
      <BreadcrumbAntd.Item key={key}>{item.label ? item.label : ''}</BreadcrumbAntd.Item>
    )
  })

  return (
    <div>
      {/* {console.log('bread', breadbrumb)} */}
      <BreadcrumbStyled {...props}>
        <BreadcrumbAntd className="breadcrumb breadcrumb__portal">{breadcrumbItem}</BreadcrumbAntd>
      </BreadcrumbStyled>
    </div>
  )
}

BreadcrumbPortal.propTypes = {
  breadbrumb: PropTypes.array
}
export default BreadcrumbPortal
