import React from 'react'
import { CheckboxStyled } from './CheckboxStyled'
import { Checkbox } from 'antd'
import PropTypes from 'prop-types'

const CheckboxPortal = (props) => {
  const { data } = props

  return (
    <CheckboxStyled>
      <div className="checkboxportal">
        <Checkbox {...props} defaultChecked={true}>
          {data.tags}
        </Checkbox>
      </div>
    </CheckboxStyled>
  )
}

CheckboxPortal.propTypes = {
  data: PropTypes.object
}
export default CheckboxPortal
