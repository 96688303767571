import React from 'react'
import { useLocation } from 'react-router-dom'

const Error404 = () => {
  const location = useLocation()

  return (
    <div>
      <h2>404 Page Not Found</h2>
      <code>Path: {location.pathname}</code>
    </div>
  )
}

export default Error404
