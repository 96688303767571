import React from 'react'
import { Outlet, useMatch } from 'react-router-dom'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../hooks/useLanguage'
import { LoginLayoutStyled, LoginLayoutGlobalStyled } from './LoginLayoutStyled'

const Loginlayout = () => {
  const { t } = useTranslation()
  const [, setIsLang] = useLanguage()

  return (
    <>
      <LoginLayoutGlobalStyled />
      <LoginLayoutStyled>
        <div className="content-top">
          <Row justify="center">
            <Col span={10}>
              <div className="logo"></div>
            </Col>
            <Col span={10}>
              {useMatch('/login') && (
                <div className="lang hide">
                  <a className="link-lang" onClick={() => setIsLang('th')}>
                    {t('ภาษาไทย')}
                  </a>{' '}
                  <span className="line">|</span>
                  <a className="link-lang" onClick={() => setIsLang('en')}>
                    {t('English')}
                  </a>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className="contents">
          <Outlet />
        </div>
      </LoginLayoutStyled>
    </>
  )
}

export default Loginlayout
