import styled from 'styled-components'
import { colors, device } from '../../styledPortal.config'
export const CardStyled = styled.div`
  .card-contentportal {
    .ant-card {
      background-color: transparent;

      .ant-col {
        @media ${device.iphone} {
          width: 100%;
        }
      }

      .text-date {
        font-size: 0.875rem;
        color: ${colors.grays66};
        margin-bottom: 5px;

        @media ${device.labtop} {
          margin-top: 0.8rem;
        }
      }

      .ant-image {
        width: 100%;
      }

      .ant-card-body {
        padding-left: 0;
        padding-bottom: 30px;
        padding-top: 0;

        @media ${device.labtop} {
          padding-right: 0;
        }
      }

      .heading-news {
        color: ${colors.blackFont};
        font-size: 1.125rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
      }

      .text-excerpt {
        font-size: 0.75rem;
        color: ${colors.grays66};
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
      }

      .image-news {
        padding-right: 15px;

        @media ${device.iphone} {
          padding-right: 0;
        }
      }
      .wrapper-btn {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;

        .eye-view {
          border: 1px solid ${colors.grays99};
          color: ${colors.black};
          padding: 0 10px;
          border-radius: 14px;
          margin-left: 15px;
          display: flex;
          align-items: center;
          height: 22px;

          img {
            width: 15px;
            position: relative;
            top: -1px;
          }

          .number-view {
            margin-left: 5px;
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  .card-other {
    .ant-card {
      background-color: transparent;

      .ant-image {
        width: 100%;
      }

      .text-date {
        font-size: 0.75rem;
        color: ${colors.grays66};
        margin-bottom: 2px;

        @media ${device.labtop} {
          font-size: 0.65rem;
        }

        @media ${device.iphone} {
          font-size: 0.75rem;
        }
      }

      .ant-card-body {
        padding-left: 0;
        padding-bottom: 18px;
        padding-top: 0;

        @media ${device.labtop} {
          padding-right: 0;
        }
      }

      .heading-news {
        color: ${colors.blackFont};
        font-size: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
      }

      .image-news {
        padding-right: 5px;
        margin-top: 4px;

        @media ${device.iphone} {
          padding-right: 15px;
        }
      }

      .eye-view {
        border: 1px solid ${colors.grays99};
        color: ${colors.black};
        padding: 0 10px;
        border-radius: 14px;
        margin-left: 0;
        display: inline-flex;
        align-items: center;
        height: 22px;

        img {
          width: 15px;
          position: relative;
          top: -1.5px;
        }

        .number-view {
          margin-left: 5px;
          font-size: 0.75rem;
        }
      }
    }
  }

  .card-pdfother {
    margin-bottom: 1rem;
    background: ${colors.white};
    border: 1px solid ${colors.pdfother};
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    &:hover {
      transition: 0.3s all ease-in-out;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    }

    .ant-card-body {
      padding: 10px;
    }

    .heading-namefile {
      font-size: 0.75rem;
      font-weight: 700;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }

    .text-date {
      font-weight: 400;
      font-size: 0.625rem;
      margin-bottom: 5px;
      color: ${colors.grays66};
    }

    .eye-view {
      border: 1px solid ${colors.grays99};
      color: ${colors.black};
      padding: 0 10px;
      border-radius: 14px;
      margin-left: 0;
      height: 22px;
      display: inline-flex;
      align-items: center;

      img {
        width: 15px;
        position: relative;
        top: -1px;
      }

      .number-view {
        margin-left: 5px;
        font-size: 0.75rem;
      }
    }
  }

  .card-pdfdownload {
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 1.5rem;

    img {
      @media ${device.iphone} {
        padding-right: 8px;
      }
    }

    .heading-files {
      font-size: 1rem;
      font-weight: 600;
      color: ${colors.blackFont};
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }

    .text-excerpt {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${colors.grays66};
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }

    .text-caragories {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${colors.blackFont};
      margin-bottom: 10px;

      span {
        margin-left: 5px;
        padding: 3px 10px;
        display: inline-block;
        border-radius: 4px;
        font-size: 0.75rem;
        background-color: ${colors.greenbgtext};
        color: ${colors.greentextupload};
      }
    }

    .text-tags {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${colors.blackFont};
      margin-bottom: 10px;

      span {
        margin-left: 5px;
        padding: 3px 10px;
        border-radius: 4px;
        font-size: 0.75rem;
        display: inline-block;
        background-color: ${colors.greenbgtext};
        color: ${colors.greentextupload};
      }
    }
    .text-uploadby {
      font-size: 0.75rem;
      color: ${colors.grays99};

      @media ${device.iphone} {
        display: block;
        margin-left: 0;
      }

      .uploaddata {
        margin-left: 5px;
      }
    }

    .text-uploaddate {
      font-size: 0.75rem;
      color: ${colors.grays99};
      margin-left: 10px;

      @media ${device.iphone} {
        display: block;
        margin-left: 0;
        margin-top: 5px;
      }

      .uploaddata {
        margin-left: 5px;
      }
    }

    .wrapper-btn {
      display: flex;
      align-items: center;

      .box-btn {
        margin-top: 1rem;
      }

      .eye-view {
        border: 1px solid ${colors.grays99};
        color: ${colors.black};
        padding: 0 10px;
        border-radius: 14px;
        margin-left: 10px;
        margin-top: 15px;
        display: flex;
        align-items: center;
        height: 22px;

        img {
          width: 15px;
          position: relative;
          top: -1px;

          @media ${device.iphone} {
            padding-right: 0;
            width: auto;
          }
        }

        .number-view {
          margin-left: 5px;
          font-size: 0.75rem;
        }
      }
    }
  }
`
