import React from 'react'
import { Row, Col } from 'antd'
import BreadcrumbPortal from '../../components/breadcrumb/BreadcrumbPortal'
import { Tabs } from 'antd'
import CardPortal from '../../components/card/CardPortal'
import Buttonportal from '../../components/button/ButtonPortal'
import { Typography } from 'antd'
const { Title } = Typography

const mockDataNews = [
  {
    images: 'https://drive.google.com/uc?id=1BYVLbgKeDne6h7HS9LscqZR3SmWBSGz6',
    dateNews: 'ข่าวประชาสัมพันธ์ / พฤหัสบดี, 7 กรกฎาคม 2564',
    headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
    excerptNews:
      'วันที่ 6 ตุลาคม 2564 สำนักงานพัฒนาพิงคนคร (องค์การมหาชน) โดยสำนักงานเชียงใหม่ไนท์ซาฟารี นำโดย นางสาวฐิติรัตน์ ต๊ะวันวงค์ ผู้อำนวยการสำนักงานเชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือ...',
    views: '3,357',
    path: '/news-detail'
  },
  {
    images: 'https://drive.google.com/uc?id=1BYVLbgKeDne6h7HS9LscqZR3SmWBSGz6',
    dateNews: 'ข่าวประชาสัมพันธ์ / พฤหัสบดี, 7 กรกฎาคม 2564',
    headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
    excerptNews:
      'วันที่ 6 ตุลาคม 2564 สำนักงานพัฒนาพิงคนคร (องค์การมหาชน) โดยสำนักงานเชียงใหม่ไนท์ซาฟารี นำโดย นางสาวฐิติรัตน์ ต๊ะวันวงค์ ผู้อำนวยการสำนักงานเชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือ...',
    views: '3,357',
    path: '/news-detail'
  },
  {
    images: 'https://drive.google.com/uc?id=1BYVLbgKeDne6h7HS9LscqZR3SmWBSGz6',
    dateNews: 'ข่าวประชาสัมพันธ์ / พฤหัสบดี, 7 กรกฎาคม 2564',
    headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
    excerptNews:
      'วันที่ 6 ตุลาคม 2564 สำนักงานพัฒนาพิงคนคร (องค์การมหาชน) โดยสำนักงานเชียงใหม่ไนท์ซาฟารี นำโดย นางสาวฐิติรัตน์ ต๊ะวันวงค์ ผู้อำนวยการสำนักงานเชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือ...',
    views: '3,357',
    path: '/news-detail'
  },
  {
    images: 'https://drive.google.com/uc?id=1BYVLbgKeDne6h7HS9LscqZR3SmWBSGz6',
    dateNews: 'ข่าวประชาสัมพันธ์ / พฤหัสบดี, 7 กรกฎาคม 2564',
    headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
    excerptNews:
      'วันที่ 6 ตุลาคม 2564 สำนักงานพัฒนาพิงคนคร (องค์การมหาชน) โดยสำนักงานเชียงใหม่ไนท์ซาฟารี นำโดย นางสาวฐิติรัตน์ ต๊ะวันวงค์ ผู้อำนวยการสำนักงานเชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือ...',
    views: '3,357',
    path: '/news-detail'
  }
]

const mockDataNewsOther = [
  {
    images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
    dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
    headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
    excerptNews: '',
    views: '3,357',
    path: '/news-detail'
  },
  {
    images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
    dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
    headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
    excerptNews: '',
    views: '3,357',
    path: '/news-detail'
  },
  {
    images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
    dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
    headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
    excerptNews: '',
    views: '3,357',
    path: '/news-detail'
  },
  {
    images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
    dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
    headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
    excerptNews: '',
    views: '3,357',
    path: '/news-detail'
  }
]

const mockDataNewsbred = [
  { label: 'หน้าแรก', path: '#' },
  { label: 'ข่าวประชาสัมพันธ์', path: '#' }
]

const mockDataButtonDownload = [
  {
    path: '#',
    propstype: 'btn-otherdocs',
    nameBtn: 'เอกสารทั่วไป'
  },
  {
    path: '#',
    propstype: 'btn-register',
    nameBtn: 'ใบสมัคร/ลงทะเบียน'
  },

  {
    path: '#',
    propstype: 'btn-manualsystem',
    nameBtn: 'คู่มือการใช้งานระบบ'
  },

  {
    path: '#',
    propstype: 'btn-petition',
    nameBtn: 'เอกสารคำร้อง'
  },

  {
    path: '#',
    propstype: 'btn-searchdocsall',
    nameBtn: 'ค้นหาเอกสารทั้งหมด'
  }
]

const tabItems = [
  {
    label: 'ล่าสุด',
    key: 'tabitem-1',
    children: mockDataNewsOther.map((items, index) => (
      <CardPortal key={index} data={{ ...items }} propstype="card-other" />
    ))
  }, // remember to pass the key prop
  {
    label: 'ยอดนิยม',
    key: 'tabitem-2',
    children: mockDataNewsOther.map((items, index) => (
      <CardPortal key={index} data={{ ...items }} propstype="card-other" />
    ))
  }
]

const News = () => {
  return (
    <div className="news-portal">
      <BreadcrumbPortal breadbrumb={mockDataNewsbred} />
      <Row>
        <Col xl={16} lg={14} md={14} sm={24}>
          <div className="title-page">
            <Title className="title-section">ข่าวประชาสัมพันธ์</Title>
          </div>
          <div className="padding-bottomnews">
            {mockDataNews.map((items, index) => (
              <CardPortal key={index} data={{ ...items }} />
            ))}
          </div>
        </Col>
        <Col xl={8} lg={10} md={10} sm={8}>
          <div className="box-newsother">
            <Title level={4} className="title-newsother">
              ข่าวประชาสัมพันธ์
            </Title>
            <Tabs items={tabItems} defaultActiveKey="1" />
          </div>

          <div className="box-download">
            <Title level={4} className="title-newsother">
              ดาวน์โหลดเอกสาร
            </Title>

            <div className="box__alldownload">
              {mockDataButtonDownload.map((items, index) => (
                <Buttonportal
                  key={index}
                  path={items.path}
                  propsbtn={items.propstype}
                  namebtn={items.nameBtn}
                ></Buttonportal>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default News
