import React from 'react'

const Dashboard = () => {
  return (
    <div>
      Dashboard .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
      .<br />
    </div>
  )
}

export default Dashboard
