import { useMutation, useQuery } from '@apollo/client'
import { Alert, Button, Col, Form, Input, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CHANGEPASSWORD } from './graphql/Mutation'
import { VALIDATEURL } from './graphql/Query'
import { useNavigate } from 'react-router-dom'
import { Spinners } from 'pda-components'
const Forgotpasswordchange = () => {
  const { t } = useTranslation()
  const { resettoken } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [errormessage, setErrormessage] = useState('')
  const [dataapi, setDataapi] = useState('')
  const [datenow, setDatenow] = useState('')
  const [datetoken, setdateToken] = useState('')

  const [changePassword, { data: changePasswordData, loading: changePasswordLoading, error: changePasswordError }] =
    useMutation(CHANGEPASSWORD)
  const { data, loading } = useQuery(VALIDATEURL, {
    variables: {
      input: { tokenDecrypt: resettoken },
      action: {
        action: 'edit'
      }
    }
  })

  const onFinish = async (values) => {
    if (values?.password && values?.confirmpassword)
      await changePassword({
        variables: {
          input: {
            newPassword: values?.password,
            confirmPassword: values?.confirmpassword,
            tokenValidate: resettoken
          },
          action: {
            action: 'changepass'
          }
        }
      })
  }

  useEffect(() => {
    if (changePasswordData) {
      form.resetFields()
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
  }, [changePasswordData])

  useEffect(() => {
    if (data) {
      if (data && data.validateUrl.userId) {
        setDatenow(new Date().toISOString())
        setdateToken(data && data.validateUrl.expireDate)
        // setdateToken('2022-10-03T08:13:15.274Z')
        setDataapi(data && data.validateUrl.userId)
      }
    }
  }, [data])

  useEffect(() => {
    if (changePasswordError) {
      setErrormessage(
        changePasswordError.message && changePasswordError.graphQLErrors.length > 0 ? changePasswordError.message : ''
      )
    }
  }, [changePasswordError])

  const conditionsToken = datetoken < datenow

  const redirect = (to) => {
    setTimeout(() => {
      navigate(to)
    }, 4000)
  }

  return (
    <>
      {loading && <Spinners text={'กรุณารอสักครู่...'} />}
      {console.log(dataapi)}
      <Row justify="center">
        <Col
          {...{
            xs: 24, //screen < 576px
            md: 11, //screen ≥ 768px
            xl: 8, //screen ≥ 1200px
            xxl: 6 //screen ≥ 1600px
          }}
        >
          <div className="content">
            <div className="title-header">{t('พิงคนคร ยินดีต้อนรับ')}</div>
            <div className="title-second">{t('กรุณากรอกรหัสผ่านใหม่')}</div>

            <Row justify="center">
              <Col
                {...{
                  xs: 22,
                  md: 22,
                  lg: 22
                }}
              >
                {!dataapi ? (
                  <div>
                    <Alert
                      type="error"
                      message={t('ไม่พบผู้ใช้งาน กรุณาลองใหม่อีกครั้ง')}
                      banner
                      style={{ marginTop: '3rem' }}
                    />
                  </div>
                ) : conditionsToken ? (
                  <div>
                    <Alert type="error" message={t('Token หมดอายุ')} banner style={{ marginTop: '3rem' }} />
                    {redirect('/forgotpassword')}
                  </div>
                ) : (
                  <Form
                    form={form}
                    name="form-login"
                    layout="vertical"
                    labelCol={{
                      span: 24
                    }}
                    wrapperCol={{
                      span: 24
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label={t('รหัสผ่านใหม่')}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: t('กรุณาระบุรหัสผ่านใหม่')
                        },
                        ({ getFieldValue }) => ({
                          validator() {
                            if (getFieldValue('password').length < 6) {
                              return Promise.reject(new Error(t('รหัสผ่านน้อยกว่า 6 หลัก')))
                            }
                            return Promise.resolve()
                          }
                        })
                      ]}
                    >
                      <Input.Password placeholder={t('Password')} className="input-password" />
                    </Form.Item>

                    <Form.Item
                      label={t('ยืนยันรหัสผ่านใหม่')}
                      name="confirmpassword"
                      rules={[
                        {
                          required: true,
                          message: t('กรุณาระบุรหัสผ่านใหม่อีกครั้ง')
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            if (getFieldValue('confirmpassword').length < 6) {
                              return Promise.reject(new Error(t('รหัสผ่านน้อยกว่า 6 หลัก')))
                            }

                            return Promise.reject(new Error(t('รหัสผ่านทั้ง 2 ช่องไม่ตรงกัน')))
                          }
                        })
                      ]}
                    >
                      <Input.Password placeholder={t('Confirm Password')} className="input-password" />
                    </Form.Item>
                    {changePasswordData && <Alert type="success" message={t('เปลี่ยนรหัสผ่านสำเร็จ')} banner />}
                    {changePasswordError && <Alert type="error" message={errormessage} banner />}
                    <div className="text-center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-login"
                        loading={changePasswordLoading ? true : false}
                      >
                        {t('เปลียนรหัสผ่าน')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Forgotpasswordchange
