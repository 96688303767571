import React, { useState, useEffect } from 'react'
import { Button, Col, Form, Input, Row, Alert } from 'antd'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { FORGOTPASSWORD } from './graphql/Mutation'
import { Helpers } from 'pda-components'

const Forgotpassword = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const { displayError } = Helpers
  const [forgotPassword, { data: emailData, loading: emailLoading, error: emailError }] = useMutation(FORGOTPASSWORD)
  const onFinish = async (values) => {
    if (values?.email) {
      await forgotPassword({
        variables: {
          input: {
            email: values?.email
          },
          action: {
            action: 'changepass'
          }
        }
      })
    }
  }

  useEffect(() => {
    if (emailData) {
      form.resetFields()
      setTimeout(() => {
        navigate('/')
      }, 4000)
    }
  }, [emailData])

  useEffect(() => {
    if (emailError) {
      setErrorMessage(emailError.message && emailError.graphQLErrors.length > 0 ? t(displayError(emailError)) : '')
    }
  }, [emailError])

  return (
    <>
      <Row justify="center">
        <Col
          {...{
            xs: 24, //screen < 576px
            md: 11, //screen ≥ 768px
            xl: 8, //screen ≥ 1200px
            xxl: 6 //screen ≥ 1600px
          }}
        >
          <div className="content">
            <div className="title-header">{t('พิงคนคร ยินดีต้อนรับ')}</div>
            <div className="title-second">{t('กรุณากรอกอีเมลเพื่อทำการเปลี่ยนรหัสผ่านใหม่')}</div>

            <Row justify="center">
              <Col
                {...{
                  xs: 22,
                  md: 22,
                  lg: 22
                }}
              >
                <Form
                  form={form}
                  name="form-login"
                  layout="vertical"
                  labelCol={{
                    span: 24
                  }}
                  wrapperCol={{
                    span: 24
                  }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label={t('อีเมล')}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t('กรุณากรอกอีเมล')
                      }
                    ]}
                  >
                    <Input placeholder={t('Email Address')} />
                  </Form.Item>
                  {emailData && (
                    <Alert type="success" message="ระบบได้ส่งลิ้งค์เปลี่ยนรหัสผ่านไปยังอีเมลของท่านแล้ว" banner />
                  )}
                  {emailError && <Alert type="error" message={errorMessage} banner />}

                  <div className="text-center">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-login"
                      loading={emailLoading ? true : false}
                    >
                      {t('ยืนยัน')}
                    </Button>

                    <div className="backtologin">
                      <Link to="/login ">
                        <u>{t('กลับไป')}</u>
                      </Link>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Forgotpassword
