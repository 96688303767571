import React from 'react'
import BreadcrumbPortal from '../../components/breadcrumb/BreadcrumbPortal'
import { Typography, Row, Col } from 'antd'
import InputPortal from '../../components/input/InputPortal'
import Buttonportal from '../../components/button/ButtonPortal'
import CardPortal from '../../components/card/CardPortal'
import CheckboxPortal from '../../components/checkbox/CheckboxPortal'

const { Title } = Typography

const DownloadFile = () => {
  const mockDataSearchbred = [
    { label: 'หน้าแรก', path: '#' },
    { label: 'ดาวน์โหลดเอกสาร', path: '#' }
  ]

  const mockDataButtonDownload = [
    {
      path: '#',
      propstype: 'btn-otherdocs',
      nameBtn: 'เอกสารทั่วไป'
    },
    {
      path: '#',
      propstype: 'btn-register',
      nameBtn: 'ใบสมัคร/ลงทะเบียน'
    },

    {
      path: '#',
      propstype: 'btn-manualsystem',
      nameBtn: 'คู่มือการใช้งานระบบ'
    },

    {
      path: '#',
      propstype: 'btn-petition',
      nameBtn: 'เอกสารคำร้อง'
    }
  ]

  const mockDataNewsOther = [
    {
      images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
      dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
      headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
      excerptNews: '',
      views: '3,357',
      path: '/news-detail'
    },
    {
      images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
      dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
      headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
      excerptNews: '',
      views: '3,357',
      path: '/news-detail'
    },
    {
      images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
      dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
      headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
      excerptNews: '',
      views: '3,357',
      path: '/news-detail'
    },
    {
      images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
      dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
      headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
      excerptNews: '',
      views: '3,357',
      path: '/news-detail'
    }
  ]

  const mockDataPdfDownload = [
    {
      nameFile: 'คู่มือการใช้งานระบบ ERP สำหรับพนักงานทั่วไป',
      excerpt:
        'คู่มือรวบรวมวิธีการใช้งานระบบ ERP กรุณาอ่านทำความเข้าใจให้ครบทุกขั้นตอน ก่อนเข้าใช้งานระบบ สำหรับสิทธิ์พนักงานทั่วไปภายในพิงคนคร ',
      catagories: 'คู่มือการใช้งานระบบ',
      tags: 'พนักงานทั่วไป',
      uploadBy: 'ฝ่ายบริหารงานทั่วไป',
      uploadDate: 'พฤ, 7 ก.ค. 2564 ',
      path: '#',
      views: '3,357'
    },
    {
      nameFile: 'คู่มือการใช้งานระบบ ERP สำหรับพนักงานทั่วไป',
      excerpt:
        'คู่มือรวบรวมวิธีการใช้งานระบบ ERP กรุณาอ่านทำความเข้าใจให้ครบทุกขั้นตอน ก่อนเข้าใช้งานระบบ สำหรับสิทธิ์พนักงานทั่วไปภายในพิงคนคร ',
      catagories: 'คู่มือการใช้งานระบบ',
      tags: 'พนักงานทั่วไป',
      uploadBy: 'ฝ่ายบริหารงานทั่วไป',
      uploadDate: 'พฤ, 7 ก.ค. 2564 ',
      path: '#',
      views: '3,357'
    },
    {
      nameFile: 'คู่มือการใช้งานระบบ ERP สำหรับพนักงานทั่วไป',
      excerpt:
        'คู่มือรวบรวมวิธีการใช้งานระบบ ERP กรุณาอ่านทำความเข้าใจให้ครบทุกขั้นตอน ก่อนเข้าใช้งานระบบ สำหรับสิทธิ์พนักงานทั่วไปภายในพิงคนคร ',
      catagories: 'คู่มือการใช้งานระบบ',
      tags: 'พนักงานทั่วไป',
      uploadBy: 'ฝ่ายบริหารงานทั่วไป',
      uploadDate: 'พฤ, 7 ก.ค. 2564 ',
      path: '#',
      views: '3,357'
    },
    {
      nameFile: 'คู่มือการใช้งานระบบ ERP สำหรับพนักงานทั่วไป',
      excerpt:
        'คู่มือรวบรวมวิธีการใช้งานระบบ ERP กรุณาอ่านทำความเข้าใจให้ครบทุกขั้นตอน ก่อนเข้าใช้งานระบบ สำหรับสิทธิ์พนักงานทั่วไปภายในพิงคนคร ',
      catagories: 'คู่มือการใช้งานระบบ',
      tags: 'พนักงานทั่วไป',
      uploadBy: 'ฝ่ายบริหารงานทั่วไป',
      uploadDate: 'พฤ, 7 ก.ค. 2564 ',
      path: '#',
      views: '3,357'
    }
  ]

  const mockDataTags = [
    { tags: 'ผู้บริหาร' },
    { tags: 'พนักงานทั่วไปริหาร' },
    { tags: 'หัวหน้างาน' },
    { tags: 'ผู้ติดต่อ' }
  ]

  return (
    <div className="downloadfile">
      <BreadcrumbPortal breadbrumb={mockDataSearchbred} />
      <Row>
        <Col xl={16} lg={14} md={14} sm={24}>
          <div className="box-search">
            <Title level={1} className="title-searchfile">
              ค้นหาและดาวน์โหลดเอกสาร
            </Title>

            <InputPortal />
            <p className="resulttotalsearch">
              120 <span>results for</span> คู่มือการใช้งานระบบ
            </p>

            <div className="box-result">
              {mockDataPdfDownload.map((items, index) => (
                <CardPortal key={index} data={{ ...items }} propstype="card-downloadpdf"></CardPortal>
              ))}

              <div className="box-btnloadmore">
                <Buttonportal path="#" propsbtn="load-more" namebtn="Load More" />
              </div>
            </div>
          </div>
        </Col>
        <Col xl={8} lg={10} md={10} sm={24}>
          <div className="box-downloadbtn">
            <Title level={4} className="title-searchfilecat">
              ค้นหาเอกสารตามหมวดหมู่
            </Title>

            <div className="box-download">
              {mockDataButtonDownload.map((items, index) => (
                <Buttonportal
                  key={index}
                  path={items.path}
                  propsbtn={items.propstype}
                  namebtn={items.nameBtn}
                ></Buttonportal>
              ))}
            </div>
          </div>

          <div className="box-tag">
            <Title level={4} className="title-tag">
              Tags
            </Title>

            {mockDataTags.map((items, index) => (
              <CheckboxPortal key={index} data={{ ...items }} />
            ))}
          </div>

          <div className="box-newsother">
            <Title level={4} className="title-newsother">
              ข่าวประชาสัมพันธ์
            </Title>

            {mockDataNewsOther.map((items, index) => (
              <CardPortal key={index} data={{ ...items }} propstype="card-other" />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default DownloadFile
