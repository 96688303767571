import styled from 'styled-components'
import { colors } from '../../styledPortal.config'
export const CheckboxStyled = styled.div`
  .checkboxportal {
    margin-bottom: 0.5rem;
    .ant-checkbox-inner {
      border-radius: 6px;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
      border: 3px solid ${colors.white};
      border-top: 0;
      border-left: 0;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: transparent;
      background: linear-gradient(180deg, ${colors.main1} 0%, ${colors.main2} 100%);
    }
  }
`
