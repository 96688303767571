import React, { forwardRef } from 'react'
import { Carousel } from 'antd'
import { CarouselStyled } from './CarouselStyled'
import PropTypes from 'prop-types'

const CarouselPortal = forwardRef((props, ref) => {
  const { data } = props

  const marquee = data.map((items, index) => {
    return (
      <div key={index}>
        <p className="content-marquee">{items.content}</p>
      </div>
    )
  })

  return (
    <CarouselStyled>
      <div className="marquee-content">
        <span className="last-title">ล่าสุด</span>
        <Carousel {...props} autoplay ref={ref} effect="scrollx" dots={false}>
          {marquee}
        </Carousel>
      </div>
    </CarouselStyled>
  )
})

CarouselPortal.displayName = 'CarouselPortal'

CarouselPortal.propTypes = {
  data: PropTypes.array
}

export default CarouselPortal
