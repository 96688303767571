import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import langEN from '../lang/en.json'
import langTH from '../lang/th.json'

const defaultLang = 'th'
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: { translations: { ...langEN } },
      th: { translations: { ...langTH } }
    },
    fallbackLng: defaultLang,
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  })

if (!localStorage.getItem('lang')) {
  i18n.changeLanguage(defaultLang)
  localStorage.setItem('lang', defaultLang)
}

export default i18n
