import styled from 'styled-components'
import { colors } from '../../styledPortal.config'

export const ButtonStyled = styled.div`
  .btn-readmore {
    height: 42px;
    background: linear-gradient(${colors.white} 0 0) padding-box, linear-gradient(to right, #e16721, #ad4549) border-box;
    border: 2px solid transparent;
    border-radius: 4px;

    span {
      background: linear-gradient(180deg, ${colors.main1} 0%, ${colors.main2} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &:hover {
      transition: all 0.3s ease-in-out;
      background: linear-gradient(to top, ${colors.main1}, ${colors.main2}) border-box;

      span {
        background: linear-gradient(180deg, ${colors.white} 0%, ${colors.white} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .btn-downloaddocs {
    width: 100%;
    padding: 2.2rem 1rem;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: ${colors.download1};
    border: 1px solid ${colors.download1};

    span {
      margin-left: 10px;
      color: ${colors.white};
      font-size: 1rem;
      font-weight: 600;
    }

    &:hover {
      transition: 0.3s all ease-in-out;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
  .btn-downloadregister {
    width: 100%;
    padding: 2.2rem 1rem;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: ${colors.download2};
    border: 1px solid ${colors.download2};

    span {
      margin-left: 10px;
      color: ${colors.white};
      font-size: 1rem;
      font-weight: 600;
    }

    &:hover {
      transition: 0.3s all ease-in-out;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .btn-downloadmanual {
    width: 100%;
    padding: 2.2rem 1rem;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: ${colors.download3};
    border: 1px solid ${colors.download3};

    span {
      margin-left: 10px;
      color: ${colors.white};
      font-size: 1rem;
      font-weight: 600;
    }

    &:hover {
      transition: 0.3s all ease-in-out;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .btn-downloadpeition {
    width: 100%;
    padding: 2.2rem 1rem;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: ${colors.download4};
    border: 1px solid ${colors.download4};

    span {
      margin-left: 10px;
      color: ${colors.white};
      font-size: 1rem;
      font-weight: 600;
    }

    &:hover {
      transition: 0.3s all ease-in-out;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .btn-searchall {
    height: 42px;
    width: 100%;
    background: linear-gradient(${colors.white} 0 0) padding-box, linear-gradient(to right, #e16721, #ad4549) border-box;
    border: 2px solid transparent;
    border-radius: 4px;

    span {
      background: linear-gradient(180deg, ${colors.main1} 0%, ${colors.main2} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &:hover {
      transition: all 0.3s ease-in-out;
      background: linear-gradient(to top, ${colors.main1}, ${colors.main2}) border-box;

      span {
        background: linear-gradient(180deg, ${colors.white} 0%, ${colors.white} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .btn-download {
    height: 42px;
    background: linear-gradient(to bottom, ${colors.main1}, ${colors.main2}) border-box;
    border: 2px solid transparent;
    border-radius: 4px;

    span {
      background: linear-gradient(180deg, ${colors.white} 0%, ${colors.white} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .btn-search {
    height: 42px;
    width: 100%;
    background: linear-gradient(to bottom, ${colors.main1}, ${colors.main2}) border-box;
    border: 2px solid transparent;
    border-radius: 4px;

    span {
      background: linear-gradient(180deg, ${colors.white} 0%, ${colors.white} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .btn-login {
    height: 40px;
    background: linear-gradient(to bottom, ${colors.main1}, ${colors.main2}) border-box;
    border: 2px solid transparent;
    border-radius: 4px;

    span {
      font-weight: 600;
      background: linear-gradient(180deg, ${colors.white} 0%, ${colors.white} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .btn-loadmore {
    height: 40px;
    padding: 5px 2rem;
    background: linear-gradient(${colors.white} 0 0) padding-box, linear-gradient(to right, #e16721, #ad4549) border-box;
    border: 2px solid transparent;
    border-radius: 4px;

    span {
      background: linear-gradient(180deg, ${colors.main1} 0%, ${colors.main2} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &:hover {
      transition: all 0.3s ease-in-out;
      background: linear-gradient(to top, ${colors.main1}, ${colors.main2}) border-box;

      span {
        background: linear-gradient(180deg, ${colors.white} 0%, ${colors.white} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
`
