import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useLanguage = () => {
  const { i18n } = useTranslation()
  const [isLang, setIsLangs] = useState(localStorage.getItem('lang'))

  useEffect(() => {
    setIsLangs(localStorage.getItem('lang'))
  }, [localStorage.getItem('lang')])

  const setLang = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
  }

  return [isLang, setLang]
}
