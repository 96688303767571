import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import Error404 from './pages/errors/Error404'
import LoginLayout from './layouts/loginLayouts/LoginLayout'
import PortalLayout from './layouts/portalLayouts/PortalLayout'
import MainLayouts from './layouts/systemLayouts/MainLayout'
import Dashboard from './pages/Home'
import Login from './pages/login/Login'
import Systems from './pages/systems/Systems'
import Main from './pages/main/Main'
import News from './pages/news/News'
import NewsDetail from './pages/newsdetail/NewsDetail'
import Downloadfile from './pages/downloadfile/DownloadFile'
import packageVersion from '../package.json'
import Forgotpassword from './pages/forgotpassword/Forgotpassword'
import Forgotpasswordchange from './pages/forgotpassword/Changepassword'
import ChangePassword from './pages/changepassword/ChangePassword'
import './App.less'

function App() {
  const caching = () => {
    const version = localStorage.getItem('version')

    if (version !== packageVersion.version) {
      if ('caches' in window) {
        caches.keys().then((names) => names.forEach((name) => caches.delete(name)))

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true)
      }

      localStorage.removeItem('version')
      localStorage.setItem('version', packageVersion.version)
    }
  }

  useEffect(() => {
    caching()
  }, [])

  return (
    <Routes>
      <Route element={<PortalLayout />}>
        <Route index element={<News />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/news" element={<News />} />
        <Route path="/news-detail" element={<NewsDetail />} />
        <Route path="/downloadfile" element={<Downloadfile />} />
        <Route path="*" element={<Error404 />} />
      </Route>
      <Route element={<LoginLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/authen" element={<Systems />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/forgotpassword/change/:resettoken" element={<Forgotpasswordchange />} />
        <Route path="/authen/changepassword" element={<ChangePassword />} />
      </Route>

      <Route element={<MainLayouts />}>
        <Route path="/authen/main" element={<Main />} />
      </Route>
    </Routes>
  )
}

export default App
