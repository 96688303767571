import { Typography } from 'antd'
import React from 'react'
import { CardStyled } from './CardStyled'
import { Card, Col, Row, Image } from 'antd'
import { Link } from 'react-router-dom'
import iconEye from '../../assets/images/icon-eye.svg'
import ButtonPortal from '../button/ButtonPortal'
const { Title } = Typography
import PropTypes from 'prop-types'
import iconFilepdf from '../../assets/images/icon-filepdf.svg'
import iconDownloadpdf from '../../assets/images/icon-downloadpdf.svg'

const CardPortal = (props) => {
  const { data, propstype } = props
  let carditems
  switch (propstype) {
    case 'card-other':
      carditems = (
        <Link to={`${data.path}`}>
          <div className="card-other">
            <Card bordered={false} {...props}>
              <Row>
                <Col span={10}>
                  <Image preview={false} className="image-news" src={data.images ? data.images : ''} />
                </Col>
                <Col span={14}>
                  <p className="text-date">{data.dateNews}</p>
                  <Title className="heading-news" level={4}>
                    {data.headingNews ? data.headingNews : ''}
                  </Title>

                  <span className="eye-view">
                    <Image preview={false} src={iconEye} />
                    <span className="number-view">{data.views ? data.views : ''}</span>
                  </span>
                </Col>
              </Row>
            </Card>
          </div>
        </Link>
      )
      break
    case 'card-pdfother':
      carditems = (
        <Link to={`${data.path}`}>
          <div className="card-pdfother">
            <Card bordered={false} {...props}>
              <Row>
                <Col span={6}>
                  <Image preview={false} src={iconFilepdf} />
                </Col>
                <Col span={18}>
                  <Title className="heading-namefile" level={5}>
                    {data.nameFile ? data.nameFile : ''}
                  </Title>
                  <p className="text-date">{data.dateUpload ? data.dateUpload : ''}</p>

                  <span className="eye-view">
                    <Image preview={false} src={iconDownloadpdf} />
                    <span className="number-view">{data.views ? data.views : ''}</span>
                  </span>
                </Col>
              </Row>
            </Card>
          </div>
        </Link>
      )
      break
    case 'card-downloadpdf':
      carditems = (
        <div className="card-pdfdownload">
          <Card bordered={false} {...props}>
            <Row>
              <Col span={4}>
                <Image preview={false} src={iconFilepdf} />
              </Col>
              <Col span={20}>
                <Title className="heading-files" level={4}>
                  {data.nameFile ? data.nameFile : ''}
                </Title>
                <p className="text-excerpt">{data.excerpt ? data.excerpt : ''}</p>
                <p className="text-caragories">
                  หมวดหมู่ : <span>{data.catagories ? data.catagories : ''}</span>
                </p>
                <p className="text-tags">
                  Tags : <span>{data.tags ? data.tags : ''}</span>
                </p>
                <span className="text-uploadby">
                  โดย : <span className="uploaddata">{data.uploadBy ? data.uploadBy : ''}</span>
                </span>
                <span className="text-uploaddate">
                  อัปโหลดเมื่อ : <span className="uploaddata">{data.uploadDate ? data.uploadDate : ''}</span>
                </span>

                <div className="wrapper-btn">
                  <div className="box-btn">
                    <ButtonPortal path="#" propsbtn="btn-downloaddocs" namebtn="ดาวน์โหลดเอกสาร" />
                  </div>
                  <span className="eye-view">
                    <Image preview={false} src={iconDownloadpdf} />
                    <span className="number-view">{data.views ? data.views : ''}</span>
                  </span>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      )
      break
    default:
      carditems = (
        <div className="card-contentportal">
          <Card bordered={false} {...props}>
            <Row>
              <Col xl={12} lg={24} md={24} sm={24}>
                <Image preview={false} className="image-news" src={data.images ? data.images : ''} />
              </Col>
              <Col xl={12} lg={24} md={24} sm={24}>
                <p className="text-date">{data.dateNews ? data.dateNews : ''}</p>
                <Title className="heading-news" level={4}>
                  {data.headingNews ? data.headingNews : ''}
                </Title>
                <p className="text-excerpt">{data.excerptNews ? data.excerptNews : ''}</p>
                <div className="wrapper-btn">
                  <ButtonPortal propsbtn="read-more" path={data.path} namebtn="Read more" />
                  <span className="eye-view">
                    <Image preview={false} src={iconEye} />
                    <span className="number-view">{data.views ? data.views : ''}</span>
                  </span>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      )
      break
  }
  return (
    <div>
      <CardStyled>{carditems}</CardStyled>
    </div>
  )
}

CardPortal.propTypes = {
  data: PropTypes.object,
  propstype: PropTypes.string
}

export default CardPortal
