import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
const { Search } = Input;
import { InputStyled } from './InputStyled'
const InputPortal = (props) => {
   return (
      <InputStyled>
         <div className="input-portal">
            <div className="input-searchicon">
               <Search
                  {...props}
                  enterButton="ค้นหาเอกสาร"
                  placeholder="Search Keywords..."
                  prefix={<SearchOutlined className="site-form-item-icon" />}
               />
            </div>
         </div>
      </InputStyled>
   )
}

export default InputPortal
