import styled from 'styled-components'
import { colors, device } from '../../styledPortal.config'

export const MenuStyled = styled.div`
  .userloginmenu {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 3px;
      height: 40px;
      top: 10px;
      right: 0;
      background-color: ${colors.white};

      @media ${device.iphone} {
        display: none;
      }
    }
  }

  .degreemenu {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 25px;
    top: 10px;

    @media ${device.iphone} {
      position: relative !important;
    }

    p {
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .ant-menu-horizontal > .ant-menu-item:after,
  .ant-menu-horizontal > .ant-menu-submenu:after {
    left: 75px;
    bottom: 26px;
    width: 10px;
    height: 10px;
  }

  .ant-menu-item-only-child {
    &:hover {
      .ant-menu-title-content {
        @media ${device.iphone} {
          color: ${colors.main1} !important;
        }
      }
    }
  }
`
