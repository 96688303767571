import React from 'react'
import { ButtonStyled } from './ButtonStyled'
import { Button, Image } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import iconDownload from '../../assets/images/icon-download.svg'

const Buttonportal = (props) => {
  const { path, propsbtn, namebtn } = props

  // console.log('path', path, 'propsbtn', propsbtn, 'namebtn', namebtn)
  let classbutton
  let icon
  switch (propsbtn) {
    case 'btn-otherdocs':
      classbutton = 'btn-downloaddocs'
      icon = iconDownload
      break
    case 'btn-register':
      classbutton = 'btn-downloadregister'
      icon = iconDownload
      break
    case 'btn-manualsystem':
      classbutton = 'btn-downloadmanual'
      icon = iconDownload
      break
    case 'btn-petition':
      classbutton = 'btn-downloadpeition'
      icon = iconDownload
      break
    case 'btn-downloaddocs':
      classbutton = 'btn-download'
      break
    case 'read-more':
      classbutton = 'btn-readmore'
      break
    case 'load-more':
      classbutton = 'btn-loadmore'
      break
    case 'btn-searchdocsall':
      classbutton = 'btn-searchall'
      break
    case 'btn-searchdocs':
      classbutton = 'btn-search'
      break
    case 'login':
      classbutton = 'btn-login'
      break
    default:
      break
  }

  return (
    <ButtonStyled>
      <Link to={path}>
        <Button className={classbutton} {...props}>
          {icon ? <Image src={icon} preview={false} /> : ''}
          <span>{namebtn}</span>
        </Button>
      </Link>
    </ButtonStyled>
  )
}
Buttonportal.propTypes = {
  path: PropTypes.string,
  propsbtn: PropTypes.string,
  namebtn: PropTypes.string
}

export default Buttonportal
