import { gql } from '@apollo/client'
export const GET_USER_MENUS = gql`
  query LayoutMenus($action: ActionInput!) {
    layoutMenus(action: $action) {
      moduleId
      menuCode
      menuNameTH
      menuNameEN
      menuPath
      menuIcon
      menuParentCode
      children {
        moduleId
        menuCode
        menuNameTH
        menuNameEN
        menuPath
        menuIcon
        menuParentCode
      }
    }
  }
`

export const GET_SESSION_EXPIRE = gql`
  query GetSessionExpire($action: ActionInput!) {
    getSessionExpire(action: $action) {
      sessionExpire
      loginFail
      lockTime
    }
  }
`

export const GET_SOFTWARE_VERSION = gql`
  query getSoftwareVersion($action: ActionInput!) {
    getSoftwareVersion(action: $action) {
      version
      date
      detail
    }
  }
`
