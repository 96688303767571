import styled from 'styled-components'
import { colors, device } from '../../styledPortal.config'

export const PortalLayoutStyled = styled.span`
  font-family: 'Kanit';

  .ant-col-17 {
    @media ${device.tablet} {
      display: initial;
      flex: 0 0 100%;
      min-width: 100%;
    }
  }

  .ant-layout {
    background: ${colors.white};
  }

  .layout-header-1 {
    background-color: ${colors.white};
    border-bottom: 1px dashed ${colors.graysd9};
    padding: 4px 0;
    height: 31px;

    .mobile-show {
      display: none;

      @media ${device.iphone} {
        position: absolute !important;
        top: 0 !important;
        right: 0;
        display: block;
      }
    }

    .box-btnslide {
      margin-right: 10px;
      position: relative;
      top: 2px;

      .btn-prev {
        width: 20px;
        height: 20px;
        padding: 3px;
        display: inline-block;
        border-radius: 50px;
        background: linear-gradient(180deg, ${colors.btncarousel1} 0%, ${colors.btncarousel2} 100%);
        border-color: ${colors.btncarousel1};
      }

      .btn-next {
        width: 20px;
        height: 20px;
        padding: 3px;
        display: inline-block;
        border-radius: 50px;
        background: linear-gradient(180deg, ${colors.btncarousel1} 0%, ${colors.btncarousel2} 100%);
        border-color: ${colors.btncarousel1};
        margin-left: 5px;
      }

      .ant-btn .anticon {
        color: ${colors.white};
        position: relative;
        top: -5px;
        font-size: 0.75rem;
      }
    }

    @media ${device.tablet} {
      padding-right: 15px;
    }

    .wrapper-menutop {
      display: flex;
      justify-content: flex-end;

      @media ${device.iphone} {
        display: none;
      }

      .box-dateandsocial {
        .box-date {
          display: inline-block;
          position: relative;

          &:after {
            position: absolute;
            content: '';
            right: -10px;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: ${colors.graysd9};
          }

          p {
            margin-bottom: 0;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
        .box-social {
          display: inline-block;
          margin-left: 10px;
          p {
            margin-bottom: 0;
          }

          .social-facebook {
            padding: 0 3px;

            .ant-image-img {
              transition: 0.5s all ease-in-out;
            }

            &:hover {
              .ant-image-img {
                transition: 0.5s all ease-in-out;
                transform: translateY(-3px);
              }
            }
          }
          .social-twitter {
            padding: 0 3px;

            .ant-image-img {
              transition: 0.5s all ease-in-out;
            }

            &:hover {
              .ant-image-img {
                transition: 0.5s all ease-in-out;
                transform: translateY(-3px);
              }
            }
          }
          .social-youtube {
            padding: 0 3px;

            .ant-image-img {
              transition: 0.5s all ease-in-out;
            }

            &:hover {
              .ant-image-img {
                transition: 0.5s all ease-in-out;
                transform: translateY(-3px);
              }
            }
          }
          .social-instagram {
            padding: 0 3px;

            .ant-image-img {
              transition: 0.5s all ease-in-out;
            }

            &:hover {
              .ant-image-img {
                transition: 0.5s all ease-in-out;
                transform: translateY(-3px);
              }
            }
          }
        }
      }
    }
  }
  .layout-header-2 {
    min-height: 100px;
    background-color: ${colors.white};

    @media ${device.iphone} {
      min-height: 85px;
    }

    @media ${device.mobile1} {
      min-height: 70px;
    }

    @media ${device.mobile2} {
      min-height: 60px;
    }

    .box-logo {
      img {
        @media ${device.iphone} {
          width: 80%;
        }
      }
    }

    @media ${device.tablet} {
      padding: 0 15px;
    }

    .wrapper-menusecond {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
    }
  }

  .layout-header-seconds {
    padding: 0px;
    height: 64px;
    background: linear-gradient(180deg, ${colors.main1} 0%, ${colors.main2} 100%);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

    .menu-top {
      padding: 0 24px;

      .drawer-mobile {
        display: none;

        @media ${device.iphone} {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .ant-btn-default {
          background-color: transparent;
        }

        .ant-btn .anticon {
          color: ${colors.white};
        }

        .box-datemobile {
          margin-bottom: 0;
          color: ${colors.white};
        }
      }

      .menu-web {
        @media ${device.iphone} {
          display: none;
        }
      }

      .logo {
        float: left;
        width: 120px;
        height: 31px;
        margin: 16px 24px 16px 0;
        background: rgba(255, 255, 255, 0.2);
      }
      .menu-web {
        background-color: transparent;
        border-bottom: unset;
        .ant-menu-item {
          color: ${colors.white};
          &::after {
            display: none;
          }
        }
      }
    }

    .ant-menu-title-content {
      color: ${colors.white};
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after {
      border-bottom: 0;
    }

    .ant-menu-horizontal > .ant-menu-item:after,
    .ant-menu-horizontal > .ant-menu-submenu:after {
      border-bottom: 0;
    }

    .ant-menu-title-content {
      &:hover {
        color: ${colors.white};
      }
    }
  }

  .layout-container {
    background-color: ${colors.graysfc};

    .layout-contents {
      .breadcrumb {
        padding: 15px 20px;
      }

      .layout-content {
        display: inherit;
        min-height: 550px;
      }
    }
  }

  .layout-footer {
    padding: 15px 0px;
    background: ${colors.grays49};
    color: ${colors.white};

    .col-footer-left,
    .col-footer-right {
      min-height: 155px;
      padding: 17px 7px;
    }

    .col-footer-left {
      display: flex;
      align-items: center;

      .logo-img {
        height: 120px;
        filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.75));
      }

      .col-footer-left-row {
        display: block;
        padding-left: 15px;

        .text1 {
          font-weight: bold;
          font-size: 16px;
        }

        .text2 {
          font-size: 16px;
        }
      }
    }
    .col-footer-right {
      display: flex;
      align-items: center;

      .col-footer-right-row {
        display: block;
        padding-left: 15px;

        .icon-tel {
          transform: rotate(-30deg);
          font-size: 24px;
        }

        .text1 {
          font-size: 16px;
        }

        .text2 {
          font-weight: bold;
          font-size: 24px;
        }
      }
    }
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .news-portal {
    .title-page {
      .title-section {
        font-size: 2rem;
        font-weight: 600;
        color: ${colors.blackFont};
        margin-bottom: 1.2rem;
      }
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      overflow: initial;
    }

    .title-newsother {
      margin-top: 0.5rem;
      font-weight: 600;
      margin-bottom: 1.2rem;
      color: ${colors.blackFont};
    }

    .padding-bottomnews {
      padding-bottom: 2rem;

      @media ${device.iphone} {
        padding-bottom: 1rem;
      }
    }

    .ant-tabs-top > .ant-tabs-nav:before,
    .ant-tabs-top > div > .ant-tabs-nav:before,
    .ant-tabs-top > .ant-tabs-nav:before,
    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 10px;
    }

    .ant-tabs-nav-list {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-tabs-tab {
        width: 100%;
        text-align: center;
        justify-content: center;
        padding: 7px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        background-color: ${colors.white};
      }
    }

    .ant-tabs-tab:hover {
      color: ${colors.black};
    }

    .ant-tabs-tab-active {
      background-color: ${colors.blue10} !important;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      padding: 7px;
      border-bottom: 0;

      .ant-tabs-tab-btn {
        color: ${colors.white};
      }
    }

    .box-download {
      margin-top: 1rem;
      @media ${device.iphone} {
        margin-bottom: 2rem;
      }
    }
  }

  // News Detail
  .news-detailportal {
    margin-bottom: 2rem;
    .wrapper-headerdetail {
      display: flex;
      justify-content: space-between;
      padding-right: 24px;
      margin-bottom: 0.5rem;

      .title-newsother {
        color: ${colors.blackFont};
      }

      .eye-view {
        border: 1px solid ${colors.grays99};
        color: ${colors.black};
        padding: 1px 10px;
        border-radius: 14px;
        margin-left: 15px;
        height: 22px;
        display: inline-flex;

        img {
          width: 15px;
          position: relative;
          top: -3px;
        }

        .number-view {
          margin-left: 5px;
          font-size: 0.75rem;
        }
      }
    }

    .box-newsdetail {
      padding-right: 24px;

      @media ${device.iphone} {
        padding-right: 0;
      }

      .title-newsheading {
        font-size: 2rem;
        font-weight: 600;
      }
      .box-content {
        img {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }

    .box-newsother {
      .title-newsother {
        margin-bottom: 1.2rem;
        color: ${colors.blackFont};
      }
    }

    .box-download {
      margin-top: 1rem;

      .title-downloadother {
        margin-bottom: 1rem;
      }
    }
  }
  // downloadfile

  .downloadfile {
    .box-search {
      padding-right: 24px;

      @media ${device.labtop} {
        padding-right: 0;
      }

      .title-searchfile {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: ${colors.blackFont};
      }

      .resulttotalsearch {
        margin-top: 1rem;
        color: ${colors.blackFont};
        font-weight: 600;

        span {
          color: ${colors.grays66};
          font-weight: 400;
        }
      }
    }

    .box-downloadbtn {
      .title-searchfilecat {
        color: ${colors.blackFont};
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 1.5rem;

        @media ${device.labtop} {
          margin-top: 0;
        }
      }
    }

    .box-newsother {
      .title-newsother {
        color: ${colors.blackFont};
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
    }

    .box-tag {
      .title-tag {
        color: ${colors.blackFont};
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
    }

    .box-btnloadmore {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  // Responsive typography
  h1.ant-typography {
    @media ${device.labtop} {
      font-size: 1.5rem !important;
    }
  }

  // Drawer

  .ant-drawer-header {
    @media ${device.iphone} {
      background: linear-gradient(180deg, ${colors.main1} 0%, ${colors.main2} 100%);
    }

    .ant-drawer-title {
      @media ${device.iphone} {
        color: ${colors.white};
        font-weight: 600;
      }
    }

    .anticon svg {
      color: ${colors.white};
    }
  }

  // social mobile
  .box-socialmobile {
    display: none;

    @media ${device.iphone} {
      display: block;
      text-align: center;
      padding-bottom: 1rem;

      .social-facebook {
        padding: 0 5px;
        filter: brightness(0) invert(1);

        .ant-image-img {
          transition: 0.5s all ease-in-out;
        }

        &:hover {
          .ant-image-img {
            transition: 0.5s all ease-in-out;
            transform: translateY(-3px);
          }
        }
      }
      .social-twitter {
        padding: 0 5px;
        filter: brightness(0) invert(1);

        .ant-image-img {
          transition: 0.5s all ease-in-out;
        }

        &:hover {
          .ant-image-img {
            transition: 0.5s all ease-in-out;
            transform: translateY(-3px);
          }
        }
      }
      .social-youtube {
        padding: 0 5px;
        filter: brightness(0) invert(1);

        .ant-image-img {
          transition: 0.5s all ease-in-out;
        }

        &:hover {
          .ant-image-img {
            transition: 0.5s all ease-in-out;
            transform: translateY(-3px);
          }
        }
      }
      .social-instagram {
        padding: 0 5px;
        filter: brightness(0) invert(1);

        .ant-image-img {
          transition: 0.5s all ease-in-out;
        }

        &:hover {
          .ant-image-img {
            transition: 0.5s all ease-in-out;
            transform: translateY(-3px);
          }
        }
      }
    }
  }
`
