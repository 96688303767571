import { Col, Row, Image } from 'antd'
import React from 'react'
import BreadcrumbPortal from '../../components/breadcrumb/BreadcrumbPortal'
import CardPortal from '../../components/card/CardPortal'
import Buttonportal from '../../components/button/ButtonPortal'
import { Typography } from 'antd'
import iconEye from '../../assets/images/icon-eye.svg'

const { Title } = Typography

const NewsDetail = () => {
  const mockDataNewsbred = [
    { label: 'หน้าแรก', path: '#' },
    { label: 'ข่าวประชาสัมพันธ์', path: '#' },
    { label: 'รายละเอียด', path: '#' }
  ]

  const mockDataNewsOther = [
    {
      images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
      dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
      headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
      excerptNews: '',
      views: '3,357',
      path: '/news-detail'
    },
    {
      images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
      dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
      headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
      excerptNews: '',
      views: '3,357',
      path: '/news-detail'
    },
    {
      images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
      dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
      headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
      excerptNews: '',
      views: '3,357',
      path: '/news-detail'
    },
    {
      images: 'https://drive.google.com/uc?id=1CBd0atcw_CJnS0kHS1jklcSaFRqEJxw7',
      dateNews: 'ข่าวจัดซื้อจัดจ้าง / พฤ, 7 ก.ค. 2564 ',
      headingNews: 'เชียงใหม่ไนท์ซาฟารี เข้าร่วมประชุมหารือและร่าง MOU ความร่วมมือบูรณาการ ด้านการพัฒนา...',
      excerptNews: '',
      views: '3,357',
      path: '/news-detail'
    }
  ]

  const mockDataPdfother = [
    {
      nameFile: 'คู่มือการใช้งานระบบ ERP สำหรับพนักงานทั่วไป',
      dateUpload: 'พฤ, 7 ก.ค. 2564 ',
      views: '3,357',
      path: '#'
    },
    {
      nameFile: 'คู่มือการใช้งานระบบ ERP สำหรับพนักงานทั่วไป',
      dateUpload: 'พฤ, 7 ก.ค. 2564 ',
      views: '3,357',
      path: '#'
    },
    {
      nameFile: 'คู่มือการใช้งานระบบ ERP สำหรับพนักงานทั่วไป',
      dateUpload: 'พฤ, 7 ก.ค. 2564 ',
      views: '3,357',
      path: '#'
    },
    {
      nameFile: 'คู่มือการใช้งานระบบ ERP สำหรับพนักงานทั่วไป',
      dateUpload: 'พฤ, 7 ก.ค. 2564 ',
      views: '3,357',
      path: '#'
    }
  ]

  return (
    <div className="news-detailportal">
      <BreadcrumbPortal breadbrumb={mockDataNewsbred} />
      <Row>
        <Col xl={16} lg={14} md={14} sm={24}>
          <div className="wrapper-headerdetail">
            <Title level={4} className="title-newsother">
              ข่าวประชาสัมพันธ์
            </Title>

            <span className="eye-view">
              <Image src={iconEye} />
              <span className="number-view">3,357</span>
            </span>
          </div>

          <div className="box-newsdetail">
            <Title level={1} className="title-newsheading">
              เชียงใหม่ไนท์ซาฟารี ต้อนรับ Pride Month และขอร่วม เป็นส่วนหนึ่งในการสนับสนุนความเท่าเทียมของกลุ่ม LGBTQ+
            </Title>

            <div className="box-content">
              <p>
                วันที่ 10 มิถุนายน 2565 สำนักงานพัฒนาพิงคนคร (องค์การมหาชน) โดยสำนักงานเชียงใหม่ไนท์ซาฟารี
                ขอร่วมเป็นหนึ่งในการสนับสนุน และต้อนรับ Pride Month เดือนแห่งการแสดงออกถึงความเท่าเทียม
                รวมทั้งสิทธิทางเพศ ในกลุ่มผู้มีความหลากหลายทางเพศ (LGBTQ+)
              </p>
              <Image preview={false} src="https://drive.google.com/uc?id=1mfQrSl9KPJMj5EcbSfXLq8UnhkQZm6bM" />
              <p>
                {' '}
                นายสายสิทธิ์ เจตสิกทัต ปฏิบัติหน้าที่แทน ผู้อำนวยการสำนักงานพัฒนาพิงคนคร เปิดเผยว่า
                ในเดือนมิถุนายนของทุกปี ตลอดทั้งเดือน จะเป็นเดือนของ Pride Month ที่ในหลายๆ ประเทศทั่วโลก
                จัดขึ้นเพื่อแสดงออกถึงความเท่าเทียมและเรียกร้องสิทธิของคนที่มีความ หลากหลายทางเพศ (LGBTQ+)
                และเพื่อรำลึกถึงวันที่เกิดความขัดแย้ง การถูกเลือกปฏิบัติของกลุ่มคนที่เป็นเพศทางเลือก ก่อนนำไปสู่
                การก่อจราจลใน Stonewall เมื่อปี 1969
                ซึ่งเหตุการณ์ดังกล่าวเกิดขึ้นในบริบททางสังคมที่ความหลากหลายทางเพศยังไม่เป็นที่ยอมรับ ของผู้คนทั่วไป
                ทั้งการแต่งตัวที่ไม่ตรงกับเพศสภาพของตัวเอง และมีความรักกับเพศเดียวกัน จะถูกคนอื่นๆ ในสังคมมองราวกับเป็น
                ผู้ก่อการร้ายไร้ซึ่งการเคารพ ไร้ซึ่งตัวตน
                แถมถูกกดขี่สารพัดเหมือนกับคนที่มีตราบาปอยู่ในตัวซ้ำยังมีโอกาสถูกจับกุมหากเปิดเผยตัวตน ต่อสาธารณะ
                และเหตุการณ์นี้ก็เป็นเสมือนจุดเริ่มต้นให้ชาว LGBTQ+ ซึ่งหมายถึงกลุ่มบุคคลที่มีความหลากหลายทางเพศ
                ลุกขึ้นยืนยัน การมีตัวตน เรียกร้องให้ยุติการถูกกระทำอย่างรุนแรง
                ยุติการเลือกปฏิบัติและเรียกร้องสิทธิเสรีภาพการใช้ชีวิตอย่างเท่าเทียมในสังคม ในฐานะมนุษย์คนหนึ่ง
              </p>
              <Image preview={false} src="https://drive.google.com/uc?id=1-3Rx3g3GawIG9ZZAEjPjNhsCp8qIZl5N" />
              <p>
                {' '}
                นางสาวฐิติรัตน์ ต๊ะวันวงค์ ผู้อำนวยการสำนักงานเชียงใหม่ไนท์ซาฟารี กล่าวเพิ่มเติมว่า
                เพราะเราเข้าใจถึงความหลากหลายและเท่าเทียม
                เชียงใหม่ไนท์ซาฟารีจึงขอร่วมเป็นส่วนหนึ่งในการสนับสนุนและต้อนรับ Pride Month
                เดือนแห่งการแสดงออกถึงความเท่าเทียม ความเสมอภาคทางเพศ เพื่อยืนยัน ย้ำเตือน การมีอยู่
                และการเรียกร้องสิทธิ์ของกลุ่มผู้มีความหลากหลายทางเพศ (LGBTQ+) โดยการแจกธงสีรุ้ง
                พร้อมติดสติ๊กเกอร์สีรุ้งให้กับนักท่องเที่ยว ซึ่งธงสีรุ้งนั้นถือเป็นสัญลักษณ์ของกลุ่ม LGBTQ+ ทั้งนี้
                เชียงใหม่ไนท์ซาฟารี พร้อมมอบความสุขและความสำคัญให้กับคนทุกเพศอย่างเท่าเทียมกัน
                เพราะความสุขไม่เคยมีคำว่าจำกัดสำหรับเชียงใหม่ไนท์ซาฟารี
              </p>
            </div>
          </div>
        </Col>
        <Col xl={8} lg={10} md={10} sm={8}>
          <div className="box-newsother">
            <Title level={4} className="title-newsother">
              ข่าวประชาสัมพันธ์
            </Title>
            {mockDataNewsOther.map((items, index) => (
              <CardPortal key={index} data={{ ...items }} propstype="card-other" />
            ))}
          </div>

          <div className="box-download">
            <Title level={4} className="title-downloadother">
              ดาวน์โหลดเอกสาร
            </Title>
            <div className="box__alldownload">
              {mockDataPdfother.map((items, index) => (
                <CardPortal key={index} data={{ ...items }} propstype="card-pdfother"></CardPortal>
              ))}
              <Buttonportal path="#" propsbtn="btn-searchdocsall" namebtn="ค้นหาเอกสารทั้งหมด"></Buttonportal>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default NewsDetail
