import React, { useState, useRef } from 'react'
import { Layout, Image, Row, Col, Drawer, Button } from 'antd'
import { ErrorBoundary, ErrorFallback } from '../ErrorFallback'
import { PortalLayoutStyled } from './PortalLayoutStyled'
import LOGOPDAERP from '../../assets/images/logo-footer-color.svg'
import { Outlet, Link } from 'react-router-dom'
import Buttonportal from '../../components/button/ButtonPortal'
import MenuPortal from '../../components/menu/MenuPortal'
import CarouselPortal from '../../components/carousel/CarouselPortal'
import { MenuOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Icon } from 'pda-components'
import logoFull from '../../assets/images/logofull.svg'
import iconFacebook from '../../assets/images/social/icon-facebook.svg'
import iconInstagram from '../../assets/images/social/icon-instagram.svg'
import iconTwitter from '../../assets/images/social/icon-twitter.svg'
import iconYoutube from '../../assets/images/social/icon-youtube.svg'
// import { useNavigate } from 'react-router-dom'

const { Header, Content, Footer } = Layout

const mockDataMarquee = [
  {
    content:
      'เชียงใหม่ไนท์ซาฟารี ร่วมกับสภากาชาดไทย จัดกิจกรรมบริจาคโลหิต 1 คนให้ หลายคนรับ (ครั้งที่ 7) เนื่องในโอกาส...'
  },
  {
    content:
      '2เชียงใหม่ไนท์ซาฟารี ร่วมกับสภากาชาดไทย จัดกิจกรรมบริจาคโลหิต 1 คนให้ หลายคนรับ (ครั้งที่ 7) เนื่องในโอกาส...'
  }
]

const PortalLayout = () => {
  const [hideContent] = useState(true)

  //force redirect /login
  window.location.href = '/login'

  // Drawer
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  // Slide
  const ref = useRef()

  const prevSlide = () => {
    ref.current.prev()
  }
  const nextSlide = () => {
    ref.current.next()
  }

  const layoutWidth = 17
  return hideContent ? null : (
    <PortalLayoutStyled>
      <Drawer title="MENU" placement="left" closable={true} onClose={onClose} getContainer={false} open={visible}>
        <MenuPortal mode="inline" />
      </Drawer>

      <div className="layout-header-1">
        <Row justify="center">
          <Col span={layoutWidth}>
            <Row>
              <Col xl={16} lg={12} md={12} sm={12}>
                <CarouselPortal ref={ref} data={mockDataMarquee} />

                <div className="box-btnslide mobile-show">
                  <Button className="btn-prev" onClick={prevSlide}>
                    <LeftOutlined />
                  </Button>

                  <Button className="btn-next" onClick={nextSlide}>
                    <RightOutlined />
                  </Button>
                </div>
              </Col>
              <Col xl={8} lg={12} md={12} sm={12}>
                <div className="wrapper-menutop">
                  <div className="box-btnslide">
                    <Button className="btn-prev" onClick={prevSlide}>
                      <LeftOutlined />
                    </Button>

                    <Button className="btn-next" onClick={nextSlide}>
                      <RightOutlined />
                    </Button>
                  </div>

                  <div className="box-dateandsocial">
                    <div className="box-date">
                      <p>วันพฤหัสบดีที่ 7 กรกฎาคม 2565</p>
                    </div>
                    <div className="box-social">
                      <Link to="#" className="social-facebook">
                        <Image preview={false} src={iconFacebook} />
                      </Link>
                      <Link to="#" className="social-twitter">
                        <Image preview={false} src={iconTwitter} />
                      </Link>
                      <Link to="#" className="social-youtube">
                        <Image preview={false} src={iconYoutube} />
                      </Link>
                      <Link to="#" className="social-instagram">
                        <Image preview={false} src={iconInstagram} />
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="layout-header-2">
        <Row justify="center">
          <Col span={layoutWidth}>
            <div className="wrapper-menusecond">
              <div className="box-logo">
                <Image preview={false} src={logoFull} />
              </div>

              <div className="box-btnmenu">
                <Buttonportal path="/login" propsbtn="login" namebtn="เข้าสู่ระบบ ERP"></Buttonportal>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row justify="center">
        <Col span={24}>
          <Header className="layout-header-seconds">
            <Row justify="center">
              <Col span={layoutWidth}>
                <div className="menu-top">
                  {/* <div className="logo" /> */}
                  <MenuPortal mode="horizontal" />

                  <div className="drawer-mobile">
                    <p className="box-datemobile">วันพฤหัสบดีที่ 7 กรกฎาคม 2565</p>

                    <Button onClick={showDrawer}>
                      <MenuOutlined />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Header>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={layoutWidth}>
          <Layout className="layout-container">
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {
                // reset the state of your app so the error doesn't happen again
              }}
            >
              <Content className="site-layout layout-contents">
                <div className="layout-content container">
                  <Outlet />
                </div>
              </Content>
            </ErrorBoundary>
          </Layout>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <Footer className="layout-footer">
            <Row justify="center">
              <Col span={layoutWidth}>
                <Row justify="center">
                  <Col xl={17} sm={24}>
                    <div className="col-footer-left">
                      <Image className="logo-img" preview={false} src={LOGOPDAERP} />

                      <div className="col-footer-left-row">
                        <div className="text1">สงวนลิขสิทธิ์ 2022 สำนักงานพัฒนาพิงคนคร (องค์การมหาชน)</div>
                        <div className="text2">55 หมู่ที่ 7 ตำบลสุเทพ อำเภอเมืองเชียงใหม่ จังหวัดเชียงใหม่ 50200</div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={7} sm={24}>
                    <div className="col-footer-right">
                      <div className="col-footer-right-row">
                        <div className="text1">
                          <Icon icon="PhoneCall" size={18} style={{ marginRight: '10px' }} />
                          โทรศัพท์กลาง
                        </div>
                        <div className="text2">053 999 000</div>
                      </div>
                    </div>

                    <div className="box-socialmobile">
                      <Link to="#" className="social-facebook">
                        <Image preview={false} src={iconFacebook} />
                      </Link>
                      <Link to="#" className="social-twitter">
                        <Image preview={false} src={iconTwitter} />
                      </Link>
                      <Link to="#" className="social-youtube">
                        <Image preview={false} src={iconYoutube} />
                      </Link>
                      <Link to="#" className="social-instagram">
                        <Image preview={false} src={iconInstagram} />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Footer>
        </Col>
      </Row>
    </PortalLayoutStyled>
  )
}

export default PortalLayout
