import React from 'react'
import { Menu, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import { MenuStyled } from './MenuStyled'
import iconSearch from '../../assets/images/icon-search.svg'
import iconUserCircle from '../../assets/images/icon-usercircle.svg'

const MenuPortal = (props) => {
  const createMarkup = () => {
    return { __html: '25&deg;C <p>Chiang Mai </p>' }
  }

  const getMarkup = () => {
    return <div dangerouslySetInnerHTML={createMarkup()} />
  }
  const navigate = useNavigate()
  return (
    <MenuStyled>
      <Menu
        {...props}
        className="menu-web"
        mode={props.mode}
        onClick={({ key }) => {
          navigate(key)
        }}
        items={[
          { label: 'หน้าหลัก', key: '/' },
          {
            label: 'ข่าวประชาสัมพันธ์ ',
            key: '/news'
          },
          { label: 'ดาวน์โหลดเอกสาร', key: '/downloadfile' },
          {
            icon: <Image preview={false} src={iconSearch} />,
            style: { position: 'absolute', right: 190 }
          },
          {
            className: 'userloginmenu',
            icon: <Image preview={false} src={iconUserCircle} />,
            style: { position: 'absolute', right: 150 }
          },
          {
            className: 'degreemenu',
            label: getMarkup(),
            style: { position: 'absolute', right: 0 }
          }
        ]}
        // items={new Array(3).fill(null).map((_, index) => ({
        //   key: String(index + 1),
        //   label: `nav ${index + 1}`
        // }))}
        defaultSelectedKeys={['2']}
      />
    </MenuStyled>
  )
}

export default MenuPortal
