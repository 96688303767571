import CryptoJS from 'crypto-js'
export function encryptData(data, secret = process.env['REACT_APP_SECRET_FE']) {
  if (typeof data !== 'undefined') {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString()
  }
  return ''
}
export function decryptData(ciphertext, secret = process.env['REACT_APP_SECRET_FE']) {
  try {
    var bytes = CryptoJS.AES.decrypt(ciphertext, secret)
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    if (decryptedData) {
      return JSON.parse(decryptedData)
    }
  } catch {
    return ''
  }
}
