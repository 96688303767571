import { gql } from '@apollo/client'
export const GET_SYSTEMS = gql`
  query LayoutSystems($action: ActionInput!) {
    layoutSystems(action: $action) {
      systemId
      systemCode
      systemNameTH
      systemNameEN
      systemPath
      systemImg
    }
  }
`
