import styled from 'styled-components'
import { colors, device } from '../../styledPortal.config'

export const CarouselStyled = styled.div`
  .marquee-content {
    height: 20px;

    @media ${device.tablet} {
      height: 20px;
    }

    .ant-carousel {
      position: relative;
      top: -23px;
      left: 75px;
    }
  }

  .ant-btn .anticon {
    color: ${colors.white};
    position: relative;
    top: -5px;
    font-size: 0.75rem;
  }

  .last-title {
    background-color: ${colors.blue10};
    padding: 5px 1rem;
    color: ${colors.white};
    font-size: 0.875rem;
    display: iniline-block;
  }

  .content-marquee {
    font-size: 0.75rem;
    color: ${colors.blackFont};
    margin-bottom: 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    width: 85%;

    @media ${device.iphone} {
      width: 70%;
    }

    @media ${device.mobile2} {
      width: 65%;
    }
  }
`
