import React, { useState, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { PDALayout } from 'pda-components'
import { ErrorBoundary, ErrorFallback } from '../ErrorFallback'

const Mainlayout = () => {
  const { state } = useLocation()
  const [system, setSystem] = useState({ systemId: '', systemName: '' })
  const navigate = useNavigate()

  useEffect(() => {
    if (!state?.systemId) {
      navigate('/authen')
    } else {
      setSystem({
        systemId: localStorage.getItem('session_expired_systemId')
          ? localStorage.getItem('session_expired_systemId')
          : state.systemId,
        systemName: state.systemName
      })
    }
  }, [state])

  return (
    <PDALayout
      system={{
        systemId: parseInt(state?.systemId ? state?.systemId : system.systemId),
        systemName: system.systemName,
        moduleId: '',
        systemMainUrl: '/authen'
      }}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Outlet />
      </ErrorBoundary>
    </PDALayout>
  )
}

export default Mainlayout
