import React from 'react'
// import { Row, Col, Button } from 'antd'
// import { Breadcrumb, Card } from 'pda-components'
// import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'

const Main = () => {
  // const { t } = useTranslation()
  // const navigate = useNavigate()

  return (
    <div>{/* Welcome */}</div>
    // <>
    //   <Breadcrumb
    //     data={[
    //       { label: 'ตั้งค่าสำหรับผู้ดูแลระบบ', path: '/components/breadcrumb' },
    //       { label: 'จัดการกลุ่มสิทธิ์ผู้ใช้งาน' }
    //     ]}
    //     title="ตั้งค่าสำหรับผู้ดูแลระบบ"
    //   />
    //   <Card title="AAA" icon="User">
    //     <div>UserList</div>
    //   </Card>
    // </>
  )
}

export default Main
