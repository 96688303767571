import { useMutation } from '@apollo/client'
import { Button, Col, Form, Input, Row } from 'antd'
import { Alert, Helpers, Spinners } from 'pda-components'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AUTH_CHANGE_PASSWORD } from './graphql/Mutation'

const ChangePassword = () => {
  const { useAuth, Actions, displayError } = Helpers
  const [user, logout] = useAuth()
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const [callFnc, { data: callData, loading: callLoading, error: callError }] = useMutation(AUTH_CHANGE_PASSWORD)

  const onFinish = async (values) => {
    if (values?.newPassword && values?.confirmPassword && values?.oldPassword) {
      setErrorField('oldPassword', null)

      await callFnc({
        variables: {
          input: {
            oldPassword: values?.oldPassword,
            newPassword: values?.newPassword,
            userId: user?.userId
          },
          ...Actions('changepass')
        }
      })
    }
  }

  useEffect(() => {
    if (callData) {
      Alert({
        type: 'success',
        title: 'เปลี่ยนรหัสผ่านสำเร็จ',
        content: t('ระบบจะทำการออกจากระบบเพื่อให้เข้าสู่ระบบใหม่ กรุณาใช้รหัสผ่านใหม่เพื่อเข้าสู่ระบบอีกครั้ง'),
        onOk() {
          logout()
        }
      })
    }
  }, [callData])

  useEffect(() => {
    if (callError) {
      const msg = displayError(callError)

      if (msg === 'AUTH_OLD_PASSWORD_INVALID') {
        setErrorField('oldPassword', t('รหัสผ่านเดิมไม่ถูกต้อง'))
      } else {
        Alert({
          type: 'error',
          title: t('ไม่สามารถเปลี่ยนรหัสผ่านได้'),
          content: t(msg),
          onOk() {}
        })
      }
    }
  }, [callError])

  // Custom throw errors fields
  const setErrorField = (name, errors) => {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false // Message errors OR remove fields errors
      }
    ])
  }

  return (
    <>
      {callLoading && <Spinners text={'กรุณารอสักครู่...'} />}
      <Row justify="center">
        <Col
          {...{
            xs: 24, //screen < 576px
            md: 11, //screen ≥ 768px
            xl: 8, //screen ≥ 1200px
            xxl: 6 //screen ≥ 1600px
          }}
        >
          <div className="content">
            <div className="title-header">{t('เปลี่ยนรหัสผ่าน')}</div>
            <div className="title-second">{t('กรุณากรอกข้อมูลเพื่อทำการเปลี่ยนรหัสผ่านใหม่')}</div>

            <Row justify="center">
              <Col
                {...{
                  xs: 22,
                  md: 22,
                  lg: 22
                }}
              >
                <Form
                  form={form}
                  name="form-login"
                  layout="vertical"
                  labelCol={{
                    span: 24
                  }}
                  wrapperCol={{
                    span: 24
                  }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label={t('รหัสผ่านเดิม')}
                    name="oldPassword"
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุรหัสผ่านเดิม')
                      }
                    ]}
                  >
                    <Input.Password placeholder={t('Old Password')} className="input-password" />
                  </Form.Item>
                  <Form.Item
                    label={t('รหัสผ่านใหม่')}
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุรหัสผ่านใหม่')
                      },
                      ({ getFieldValue }) => ({
                        validator() {
                          if (getFieldValue('newPassword')?.length < 6) {
                            return Promise.reject(new Error(t('รหัสผ่านน้อยกว่า 6 หลัก')))
                          }
                          return Promise.resolve()
                        }
                      })
                    ]}
                  >
                    <Input.Password placeholder={t('New Password')} className="input-password" />
                  </Form.Item>

                  <Form.Item
                    label={t('ยืนยันรหัสผ่านใหม่')}
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุรหัสผ่านใหม่อีกครั้ง')
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve()
                          }
                          if (getFieldValue('confirmPassword')?.length < 6) {
                            return Promise.reject(new Error(t('รหัสผ่านน้อยกว่า 6 หลัก')))
                          }

                          return Promise.reject(new Error(t('ยืนยันรหัสผ่านไม่ถูกต้อง')))
                        }
                      })
                    ]}
                  >
                    <Input.Password placeholder={t('Confirm Password')} className="input-password" />
                  </Form.Item>

                  <div className="text-center">
                    <Button type="primary" htmlType="submit" className="btn-login" loading={callLoading ? true : false}>
                      {t('เปลี่ยนรหัสผ่าน')}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ChangePassword
