export const colors = {
  main1: '#e16721',
  main2: '#ad4549',
  white: '#ffffff',
  black: '#000000',
  blackFont: '#333333',
  grays66: '#666666',
  grays99: '#999999',
  graysf1: '#f1f1f1',
  grays49: '#494954',
  graysfc: '#fcfcfc',
  graysd9: '#d9d9d9',
  graysf9: '#f9f9f9',
  graysf5: '#f5f5f5',
  blue10: '#1091FF',
  download1: '#2A88DF',
  download2: '#2AA9DF',
  download3: '#ED9C23',
  download4: '#3ABB7D',
  pdfother: '#95AAC9',
  greentextupload: '#267659',
  greenbgtext: '#CEF0E4',
  btncarousel1: '#ecc150',
  btncarousel2: '#c6742e'
}

const size = {
  labtop: '1024px',
  tablet: '768px',
  mobile0: '576px',
  iphone: '429px',
  mobile1: '375px',
  mobile2: '320px'
}

export const device = {
  labtop: `(max-width: ${size.labtop})`,
  tablet: `(max-width: ${size.tablet})`,
  mobile0: `(max-width: ${size.mobile0})`,
  iphone: `(max-width: ${size.iphone})`,
  mobile1: `(max-width: ${size.mobile1})`,
  mobile2: `(max-width: ${size.mobile2})`
}
