import styled from 'styled-components'
import { colors } from '../../styledPortal.config'
export const InputStyled = styled.div`
  .input-portal {
    .input-searchicon {
      padding: 10px;
      background: ${colors.white};
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      .ant-input-affix-wrapper {
        border: none;
        height: 40px;

        &:focus {
          box-shadow: none;
        }
      }

      .ant-input-affix-wrapper-focused,
      .ant-input-affix-wrapper:focus {
        box-shadow: none;
      }

      .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
        border-radius: 4px;
      }

      .ant-btn-primary {
        height: 42px;
        width: 100%;
        background: linear-gradient(to bottom, ${colors.main1}, ${colors.main2}) border-box;
        border: 2px solid transparent;
        border-radius: 4px;

        span {
          font-weight: 600;
          background: linear-gradient(180deg, ${colors.white} 0%, ${colors.white} 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
  }
`
